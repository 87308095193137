export default [
  {
    route: 'company-dashboard',
    title: 'company-dashboard',
    slug: 'dashboard',
    icon: 'HomeIcon',
    i18n: 'Dashboard',
    resource: 'Company-dashboard',
    action: 'read',
  },
  {
    route: null,
    title: 'company-users',
    slug: 'users',
    icon: 'UsersIcon',
    resource: 'Company-users',
    i18n: 'Users',
    children: [
      {
        route: 'company-users-admin',
        title: 'company-users-admin',
        slug: 'userAdmin',
        icon: 'BriefcaseIcon',
        i18n: 'Admin',
        resource: 'Company-users-admin',
        action: 'read',
      },
      {
        route: 'company-users-staff',
        title: 'company-users-staff',
        slug: 'userStaff',
        icon: 'PackageIcon',
        i18n: 'Staff',
        resource: 'Company-users-staff',
        action: 'read',
      },
    ],
  },
  {
    route: 'company-application',
    title: 'company-application',
    slug: 'application',
    icon: 'PocketIcon',
    i18n: 'Application',
    resource: 'Company-application',
    action: 'read',
  },
]
