export default [
  {
    path: '/delivery',
    meta: {
      service: 'delivery',
      resource: 'Delivery-dashboard',
      action: 'read',
    },
    redirect: { name: 'delivery-dashboard' },
  },
  {
    path: '/delivery/dashboard',
    name: 'delivery-dashboard',
    component: () => import('../../views/services/delivery/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      service: 'delivery',
      resource: 'Delivery-dashboard',
      action: 'read',
    },
  },
  {
    path: '/delivery/users/ability/:id',
    name: 'delivery-users-ability',
    component: () => import('../../views/services/delivery/users/ability/Settings.vue'),
    meta: {
      pageTitle: 'Users Ability',
      service: 'delivery',
      resource: 'Delivery-users-ability',
      action: 'read',
      breadcrumb: [
        {
          text: 'Staff',
          to: '/delivery/users/staff',
        },
        {
          text: 'Ability',
          active: true,
        },
      ],
      navActiveLink: 'delivery-users-staff',
    },
  },
  {
    path: '/delivery/users/admin',
    name: 'delivery-users-admin',
    component: () => import('../../views/services/delivery/users/Users.vue'),
    meta: {
      pageTitle: 'Users Admin',
      service: 'delivery',
      resource: 'Delivery-users-admin',
      action: 'read',
    },
  },
  {
    path: '/delivery/users/staff',
    name: 'delivery-users-staff',
    component: () => import('../../views/services/delivery/users/Users.vue'),
    meta: {
      pageTitle: 'Users Staff',
      service: 'delivery',
      resource: 'Delivery-users-staff',
      action: 'read',
    },
  },
]
