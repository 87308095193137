export default [
  {
    route: 'hero-dashboard',
    title: 'hero-dashboard',
    slug: 'dashboard',
    icon: 'GridIcon',
    i18n: 'Dashboard',
    resource: 'Hero-dashboard',
    action: 'read',
  },
  {
    route: null,
    title: 'hero-report',
    slug: 'report',
    icon: 'TrendingUpIcon',
    resource: 'Hero-report',
    i18n: 'Report',
    children: [
      {
        route: 'hero-report-pdpa',
        title: 'hero-report-pdpa',
        slug: 'ReportPDPA',
        icon: 'CircleIcon',
        i18n: 'Report PDPA',
        resource: 'Hero-report-pdpa',
        action: 'read',
      },
      {
        route: 'hero-report-service',
        title: 'hero-report-service',
        slug: 'ReportService',
        icon: 'CircleIcon',
        i18n: 'Report Service',
        resource: 'Hero-report-service',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'Review',
    slug: 'review',
    icon: 'StarIcon',
    resource: 'Hero-review',
    i18n: 'Review',
    children: [
      {
        route: 'hero-question-review',
        title: 'hero-question-review',
        slug: 'questionReview',
        icon: 'CircleIcon',
        i18n: 'Question Review',
        resource: 'Hero-question-review',
        action: 'read',
      },
      {
        route: 'hero-question-review-report',
        title: 'hero-question-review-report',
        slug: 'questionReviewReport',
        icon: 'CircleIcon',
        i18n: 'Question Review Report',
        resource: 'Hero-question-review-report',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-queue-system',
    slug: 'queueSystem',
    icon: 'BookIcon',
    resource: 'Hero-queue-system',
    i18n: 'Queue System',
    children: [
      {
        route: 'hero-queue-book',
        title: 'hero-queue-book',
        slug: 'book',
        icon: 'BookIcon',
        i18n: 'Queue Book',
        resource: 'Hero-queue-book',
        action: 'read',
      },
      {
        route: 'hero-queue-monitor',
        title: 'hero-queue-monitor',
        slug: 'monitor',
        icon: 'MonitorIcon',
        i18n: 'Queue Monitor',
        resource: 'Hero-queue-monitor',
        action: 'read',
      },
      {
        route: 'hero-list-service-queue',
        title: 'hero-list-service-queue',
        slug: 'listService',
        icon: 'HomeIcon',
        i18n: 'List Service Queue',
        resource: 'Hero-list-service-queue',
        action: 'read',
      },
      {
        route: 'hero-queue-creation-date',
        title: 'hero-queue-creation-date',
        slug: 'queueCreationDate',
        icon: 'HomeIcon',
        i18n: 'Queue Creation Date',
        resource: 'Hero-queue-creation-date',
        action: 'read',
      },
      // {
      //   route: 'hero-queue-template-details',
      //   title: 'hero-queue-template-details',
      //   slug: 'queueTemplateDetails',
      //   icon: 'FileIcon',
      //   i18n: 'Queue Template Details',
      //   resource: 'Hero-queue-template-details',
      //   action: 'read',
      // },
    ],
  },
  {
    route: null,
    title: 'hero-services-outside-work',
    slug: 'outsideWork',
    icon: 'BookIcon',
    resource: 'Hero-services-outside-work',
    i18n: 'Outside Work',
    children: [
      {
        route: 'hero-services-template',
        title: 'hero-services-template',
        slug: 'servicesTemplate',
        icon: 'BoxIcon',
        i18n: 'Services',
        resource: 'Hero-services-template',
        action: 'read',
      },
      {
        route: 'hero-services-task',
        title: 'hero-services-task',
        slug: 'servicesTask',
        icon: 'BoxIcon',
        i18n: 'Assign',
        resource: 'Hero-services-task',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-delivery-system',
    slug: 'deliverySystem',
    icon: 'CoffeeIcon',
    resource: 'Hero-delivery-system',
    i18n: 'Delivery System',
    children: [
      {
        route: 'hero-grocery-monitor',
        title: 'hero-grocery-monitor',
        slug: 'groceryMonitor',
        icon: 'MonitorIcon',
        i18n: 'Grocery Monitor',
        resource: 'Hero-grocery-monitor',
        action: 'read',
      },
      {
        route: 'hero-food-monitor',
        title: 'hero-food-monitor',
        slug: 'foodMonitor',
        icon: 'MonitorIcon',
        i18n: 'Food Monitor',
        resource: 'Hero-food-monitor',
        action: 'read',
      },
      {
        route: 'hero-delivery-monitor',
        title: 'hero-delivery-monitor',
        slug: 'deliveryMonitor',
        icon: 'MonitorIcon',
        i18n: 'Delivery Monitor',
        resource: 'Hero-delivery-monitor',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-call-system',
    slug: 'callSystem',
    icon: 'TruckIcon',
    resource: 'Hero-call-system',
    i18n: 'Call System',
    children: [
      {
        route: 'hero-call-monitor',
        title: 'hero-call-monitor',
        slug: 'monitor',
        icon: 'MonitorIcon',
        i18n: 'Call Monitor',
        resource: 'Hero-call-monitor',
        action: 'read',
      },
      {
        route: 'hero-list-service-call',
        title: 'hero-list-service-call',
        slug: 'listService',
        icon: 'HomeIcon',
        i18n: 'List Service Call',
        resource: 'Hero-list-service-call',
        action: 'read',
      },
      {
        route: 'hero-driver-type',
        title: 'hero-driver-type',
        slug: 'driverType',
        icon: 'TruckIcon',
        i18n: 'Driver Type',
        resource: 'Hero-driver-type',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-services-system',
    slug: 'servicesSystem',
    icon: 'BriefcaseIcon',
    resource: 'Hero-services-system',
    i18n: 'Services System',
    children: [
      {
        route: 'hero-services-monitor',
        title: 'hero-services-monitor',
        slug: 'servicesMonitor',
        icon: 'MonitorIcon',
        i18n: 'Services Monitor',
        resource: 'Hero-services-monitor',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-live-streaming-system',
    slug: 'liveStreamingSystem',
    icon: 'TvIcon',
    resource: 'Hero-live-streaming-system',
    i18n: 'Live Streaming System',
    children: [
      {
        route: 'hero-live-streaming-system-monitor',
        title: 'hero-live-streaming-system-monitor',
        slug: 'liveStreamingSystemMonitor',
        icon: 'MonitorIcon',
        i18n: 'Live Streaming System Monitor',
        resource: 'Hero-live-streaming-system-monitor',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-manage',
    slug: 'manage',
    icon: 'PackageIcon',
    resource: 'Hero-manage',
    i18n: 'Manage Service',
    children: [
      {
        route: 'hero-service',
        title: 'hero-service',
        slug: 'service',
        icon: 'PackageIcon',
        i18n: 'Service',
        resource: 'Hero-service',
        action: 'read',
      },
      {
        route: 'hero-service-type',
        title: 'hero-service-type',
        slug: 'serviceType',
        icon: 'BriefcaseIcon',
        i18n: 'Service Type',
        resource: 'Hero-service-type',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-product-system',
    slug: 'productSystem',
    icon: 'PackageIcon',
    resource: 'Hero-product-system',
    i18n: 'Product system',
    children: [
      {
        route: 'hero-product-category',
        title: 'hero-product-category',
        slug: 'productCategory',
        icon: 'BoxIcon',
        i18n: 'Product Category',
        resource: 'Hero-product-category',
        action: 'read',
      },
      {
        route: 'hero-product',
        title: 'hero-product',
        slug: 'product',
        icon: 'BoxIcon',
        i18n: 'Product',
        resource: 'Hero-product',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-search-option',
    slug: 'searchOption',
    icon: 'FilterIcon',
    resource: 'Hero-search-option',
    i18n: 'Search Option',
    children: [
      {
        route: 'hero-search-option-continuous-option',
        title: 'hero-search-option-continuous-option',
        slug: 'continuousOption',
        icon: 'FilterIcon',
        i18n: 'Continuous option',
        resource: 'Hero-search-option-continuous-option',
        action: 'read',
      },
      {
        route: 'hero-search-option-option',
        title: 'hero-search-option-option',
        slug: 'option',
        icon: 'FilterIcon',
        i18n: 'Option',
        resource: 'Hero-search-option-option',
        action: 'read',
      },
    ],
  },

  {
    route: 'hero-template-details',
    title: 'hero-template-details',
    slug: 'templateDetails',
    icon: 'FileIcon',
    i18n: 'Template Details',
    resource: 'Hero-template-details',
    action: 'read',
  },
  {
    route: 'hero-ranking',
    title: 'hero-ranking',
    slug: 'Ranking',
    icon: 'CircleIcon',
    i18n: 'Ranking',
    resource: 'Hero-ranking',
    action: 'read',
  },

  {
    route: null,
    title: 'hero-manage-tags',
    slug: 'Manage Tags',
    icon: 'CircleIcon',
    resource: 'Hero-manage-tags',
    i18n: 'Manage Tags',
    children: [
      {
        route: 'hero-main-tags',
        title: 'hero-main-tags',
        slug: 'MainTags',
        icon: 'CircleIcon',
        i18n: 'Main Tags',
        resource: 'Hero-main-tags',
        action: 'read',
      },
      {
        route: 'hero-secondary-tags',
        title: 'hero-secondary-tags',
        slug: 'SecondaryTags',
        icon: 'CircleIcon',
        i18n: 'Secondary Tags',
        resource: 'Hero-secondary-tags',
        action: 'read',
      },
    ],
  },
  {
    route: 'hero-member',
    title: 'hero-member',
    slug: 'member',
    icon: 'UsersIcon',
    i18n: 'Member',
    resource: 'Hero-member',
    action: 'read',
  },
  {
    route: null,
    title: 'hero-partner',
    slug: 'partner',
    icon: 'UsersIcon',
    resource: 'Hero-partner',
    i18n: 'Partner',
    children: [
      {
        route: 'hero-partner-package',
        title: 'hero-partner-package',
        slug: 'packagePartner',
        icon: 'BriefcaseIcon',
        i18n: 'Partner Package',
        resource: 'Hero-partner-package',
        action: 'read',
      },
      {
        route: 'hero-partner-type',
        title: 'hero-partner-type',
        slug: 'typePartner',
        icon: 'BriefcaseIcon',
        i18n: 'Partner Type',
        resource: 'Hero-partner-type',
        action: 'read',
      },
      {
        route: 'hero-partner-users',
        title: 'hero-partner-users',
        slug: 'usersPartner',
        icon: 'BriefcaseIcon',
        i18n: 'Partner Users',
        resource: 'Hero-partner-users',
        action: 'read',
      },
      {
        route: 'hero-partner-template-time',
        title: 'hero-partner-template-time',
        slug: 'templateTimePartner',
        icon: 'BriefcaseIcon',
        i18n: 'Partner Template Time',
        resource: 'Hero-partner-template-time',
        action: 'read',
      },
    ],
  },
  {
    route: 'hero-manage-profile',
    title: 'hero-manage-profile',
    slug: 'profile',
    icon: 'UsersIcon',
    i18n: 'Profile',
    resource: 'Hero-profile',
    action: 'read',
  },
  {
    route: 'hero-package-purchase',
    title: 'hero-package-purchase',
    slug: 'package',
    icon: 'GiftIcon',
    i18n: 'Package Purchase',
    resource: 'Hero-package-purchase',
    action: 'read',
  },
  // {
  //   route: null,
  //   title: 'hero-package',
  //   slug: 'package',
  //   icon: 'BriefcaseIcon',
  //   resource: 'Hero-package',
  //   i18n: 'Package',
  //   children: [
  //     {
  //       route: 'hero-package-purchase',
  //       title: 'hero-package-purchase',
  //       slug: 'coupon',
  //       icon: 'GiftIcon',
  //       i18n: 'Package Purchase',
  //       resource: 'Hero-package-purchase',
  //       action: 'read',
  //     },
  //   ],
  // },
  {
    route: 'hero-services-list',
    title: 'hero-services-list',
    slug: 'ServicesList',
    icon: 'CircleIcon',
    i18n: 'ServicesList',
    resource: 'Hero-services-list',
    action: 'read',
  },
  // {
  //   route: 'hero-public-relations',
  //   title: 'hero-public-relations',
  //   slug: 'public-relations',
  //   icon: 'BellIcon',
  //   i18n: 'Public Relations',
  //   resource: 'Hero-public-relations',
  //   action: 'read',
  // },
  {
    route: null,
    title: 'hero-public-relations',
    slug: 'public-relations',
    icon: 'BellIcon',
    i18n: 'Public Relations',
    resource: 'Hero-public-relations',
    children: [
      {
        route: 'hero-public-relations-list',
        title: 'hero-public-relations-list',
        slug: 'public-relations-list',
        icon: 'BellIcon',
        i18n: 'List',
        resource: 'Hero-public-relations-list',
        action: 'read',
      },
      {
        route: 'hero-public-relations-automation',
        title: 'hero-public-relations-automation',
        slug: 'public-relations-automation',
        icon: 'BellIcon',
        i18n: 'Automation',
        resource: 'Hero-public-relations-automation',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-setup-delivery',
    slug: 'setupDelivery',
    icon: 'ToolIcon',
    i18n: 'Setup Delivery',
    resource: 'Hero-setup-delivery',
    children: [
      {
        route: 'hero-setup-delivery-call',
        title: 'hero-setup-delivery-call',
        slug: 'setupDeliveryCall',
        icon: 'MonitorIcon',
        i18n: 'Call',
        resource: 'Hero-setup-delivery-call',
        action: 'read',
      },
      {
        route: 'hero-setup-delivery-massenger',
        title: 'hero-setup-delivery-massenger',
        slug: 'setupDeliveryMassenger',
        icon: 'MonitorIcon',
        i18n: 'Massenger',
        resource: 'Hero-setup-delivery-massenger',
        action: 'read',
      },
      {
        route: 'hero-setup-delivery-delivery',
        title: 'hero-setup-delivery-delivery',
        slug: 'setupDeliveryDelivery',
        icon: 'MonitorIcon',
        i18n: 'Delivery',
        resource: 'Hero-setup-delivery-delivery',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-wallet',
    slug: 'wallet',
    icon: 'CreditCardIcon',
    resource: 'Hero-wallet',
    i18n: 'Wallet',
    children: [
      {
        route: 'hero-wallet-member',
        title: 'hero-wallet-member',
        slug: 'walletMember',
        icon: 'UsersIcon',
        i18n: 'Member',
        resource: 'Hero-wallet-member',
        action: 'read',
      },
      {
        route: 'hero-wallet-partner',
        title: 'hero-wallet-partner',
        slug: 'walletMember',
        icon: 'UsersIcon',
        i18n: 'Partner',
        resource: 'Hero-wallet-partner',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-wallet-balance',
    slug: 'walletBalance',
    icon: 'DollarSignIcon',
    resource: 'Hero-wallet-balance',
    i18n: 'Wallet Balance',
    children: [
      {
        route: 'hero-wallet-balance-member',
        title: 'hero-wallet-balance-member',
        slug: 'walletBalanceMember',
        icon: 'UsersIcon',
        i18n: 'Member',
        resource: 'Hero-wallet-balance-member',
        action: 'read',
      },
      {
        route: 'hero-wallet-balance-partner',
        title: 'hero-wallet-balance-partner',
        slug: 'walletBalanceMember',
        icon: 'UsersIcon',
        i18n: 'Partner',
        resource: 'Hero-wallet-balance-partner',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-point',
    slug: 'point',
    icon: 'CreditCardIcon',
    resource: 'Hero-point',
    i18n: 'Point',
    children: [
      {
        route: 'hero-point-member',
        title: 'hero-point-member',
        slug: 'pointMember',
        icon: 'UsersIcon',
        i18n: 'Member',
        resource: 'Hero-point-member',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-point-balance',
    slug: 'pointBalance',
    icon: 'DollarSignIcon',
    resource: 'Hero-point-balance',
    i18n: 'Point Balance',
    children: [
      {
        route: 'hero-point-balance-member',
        title: 'hero-point-balance-member',
        slug: 'pointBalanceMember',
        icon: 'UsersIcon',
        i18n: 'Member',
        resource: 'Hero-point-balance-member',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-privilege',
    slug: 'privilege',
    icon: 'SunIcon',
    resource: 'Hero-privilege',
    i18n: 'Privilege',
    children: [
      {
        route: 'hero-privilege-coupon',
        title: 'hero-privilege-coupon',
        slug: 'coupon',
        icon: 'GiftIcon',
        i18n: 'Coupon',
        resource: 'Hero-privilege-coupon',
        action: 'read',
      },
      {
        route: 'hero-privilege-promotion',
        title: 'hero-privilege-promotion',
        slug: 'promotion',
        icon: 'GiftIcon',
        i18n: 'Promotion',
        resource: 'Hero-privilege-promotion',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-partner-privileges',
    slug: 'partnerPrivileges',
    icon: 'SunIcon',
    resource: 'Hero-partner-privileges',
    i18n: 'Partner Privileges',
    children: [
      {
        route: 'hero-partner-privileges-coupon',
        title: 'hero-partner-privileges-coupon',
        slug: 'coupon',
        icon: 'GiftIcon',
        i18n: 'Coupon',
        resource: 'Hero-partner-privileges-coupon',
        action: 'read',
      },
      {
        route: 'hero-partner-privileges-promotion',
        title: 'hero-partner-privileges-promotion',
        slug: 'promotion',
        icon: 'GiftIcon',
        i18n: 'Promotion',
        resource: 'Hero-partner-privileges-promotion',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-rating',
    slug: 'rating',
    icon: 'StarIcon',
    i18n: 'Rating',
    resource: 'Hero-rating',
    children: [
      {
        route: 'hero-rating-store-food',
        title: 'hero-rating-store-food',
        slug: 'ratingStoreFood',
        icon: 'GiftIcon',
        i18n: 'Store Food',
        resource: 'Hero-rating-store-food',
        action: 'read',
      },
      {
        route: 'hero-rating-store-queue',
        title: 'hero-rating-store-queue',
        slug: 'ratingStoreQueue',
        icon: 'GiftIcon',
        i18n: 'Store Queue',
        resource: 'Hero-rating-store-queue',
        action: 'read',
      },
      {
        route: 'hero-rating-driver-call',
        title: 'hero-rating-driver-call',
        slug: 'ratingDriverCall',
        icon: 'GiftIcon',
        i18n: 'Driver Call',
        resource: 'Hero-rating-driver-call',
        action: 'read',
      },
      {
        route: 'hero-rating-driver-delivery',
        title: 'hero-rating-driver-delivery',
        slug: 'ratingDriverDelivery',
        icon: 'GiftIcon',
        i18n: 'Driver Delivery',
        resource: 'Hero-rating-driver-delivery',
        action: 'read',
      },
      {
        route: 'hero-rating-services',
        title: 'hero-rating-services',
        slug: 'ratingServices',
        icon: 'GiftIcon',
        i18n: 'Services',
        resource: 'Hero-rating-services',
        action: 'read',
      },
    ],
  },
  {
    route: 'hero-support',
    title: 'hero-support',
    slug: 'support',
    icon: 'PhoneIcon',
    i18n: 'Support',
    resource: 'Hero-support',
    action: 'read',
  },
  {
    route: 'hero-chat-list',
    title: 'hero-chat-list',
    slug: 'chat',
    icon: 'CircleIcon',
    i18n: 'Chat',
    resource: 'Hero-chat-list',
    action: 'read',
  },
  {
    route: null,
    title: 'hero-users',
    slug: 'users',
    icon: 'UsersIcon',
    resource: 'Hero-users',
    i18n: 'Admin',
    children: [
      {
        route: 'hero-users-admin',
        title: 'hero-users-admin',
        slug: 'userAdmin',
        icon: 'BriefcaseIcon',
        i18n: 'Admin',
        resource: 'Hero-users-admin',
        action: 'read',
      },
      {
        route: 'hero-users-staff',
        title: 'hero-users-staff',
        slug: 'userStaff',
        icon: 'PackageIcon',
        i18n: 'Staff',
        resource: 'Hero-users-staff',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-documents',
    slug: 'documents',
    icon: 'FileIcon',
    resource: 'Hero-in-documents',
    i18n: 'Check Documents',
    children: [
      {
        route: 'hero-documents-bank',
        title: 'hero-documents-bank',
        slug: 'documentsBank',
        icon: 'HomeIcon',
        i18n: 'Bank',
        resource: 'Hero-documents-bank',
        action: 'read',
      },
      {
        route: 'hero-document',
        title: 'hero-document',
        slug: 'document',
        icon: 'FileIcon',
        i18n: 'Document',
        resource: 'Hero-document',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-in-application',
    slug: 'inApplication',
    icon: 'CameraIcon',
    resource: 'Hero-in-application',
    i18n: 'In Application',
    children: [
      {
        route: 'hero-in-application-ads',
        title: 'hero-in-application-ads',
        slug: 'ads',
        icon: 'MonitorIcon',
        i18n: 'Ads',
        resource: 'Hero-in-application-ads',
        action: 'read',
      },
      {
        route: 'hero-in-application-slide',
        title: 'hero-in-application-slide',
        slug: 'slide',
        icon: 'StarIcon',
        i18n: 'Slide',
        resource: 'Hero-in-application-slide',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'hero-manage-settings',
    slug: 'manage',
    icon: 'ListIcon',
    i18n: 'Manage Setting',
    resource: 'Hero-manage-settings',
    action: 'read',
    children: [
      {
        route: 'hero-manage-bank',
        title: 'hero-manage-bank',
        slug: 'manage-bank',
        icon: 'HomeIcon',
        i18n: 'Manage Bank',
        resource: 'Hero-manage-bank',
        action: 'read',
      },
      {
        route: 'hero-images-settings',
        title: 'hero-images-settings',
        slug: 'imagesSettings',
        icon: 'SettingsIcon',
        i18n: 'Images Settings',
        resource: 'Hero-images-settings',
        action: 'read',
      },
      {
        route: 'hero-manage-applications',
        title: 'hero-manage-applications',
        slug: 'manage-applications',
        icon: 'ToolIcon',
        i18n: 'Manage Applications',
        resource: 'Hero-manage-applications',
        action: 'read',
      },
      {
        route: 'hero-system-settings',
        title: 'hero-system-settings',
        slug: 'system-settings',
        icon: 'SettingsIcon',
        i18n: 'System Settings',
        resource: 'Hero-system-settings',
        action: 'read',
      },
      {
        route: 'hero-service-config-i18n',
        title: 'hero-service-config-i18n',
        slug: 'i18n-settings',
        icon: 'SettingsIcon',
        i18n: 'i18n Settings',
        resource: 'Hero-services-i18n',
        action: 'read',
      },
      {
        route: 'hero-line-notify',
        title: 'hero-line-notify',
        slug: 'line-notify',
        icon: 'SettingsIcon',
        i18n: 'Line Notify',
        resource: 'Hero-line-notify',
        action: 'read',
      },
    ],
  },
  {
    route: 'hero-adjust-time-queue',
    title: 'hero-adjust-time-queue',
    slug: 'adjust-time-queue',
    icon: 'ClockIcon',
    i18n: 'Adjust Time Queue',
    resource: 'Hero-adjust-time-queue',
    action: 'read',
  },
  {
    route: 'hero-agency',
    title: 'hero-agency',
    slug: 'agency',
    icon: 'UsersIcon',
    i18n: 'Agency',
    resource: 'Hero-agency',
    action: 'read',
  },
  {
    route: 'hero-department',
    title: 'hero-department',
    slug: 'department',
    icon: 'UsersIcon',
    i18n: 'Department',
    resource: 'Hero-department',
    action: 'read',
  },
  // {
  //   route: 'hero-question',
  //   title: 'hero-question',
  //   slug: 'question',
  //   icon: 'MessageSquareIcon',
  //   i18n: 'Question',
  //   resource: 'Hero-question',
  //   action: 'read',
  // },
  {
    route: 'hero-service-area',
    title: 'hero-service-area',
    slug: 'service-area',
    icon: 'MapIcon',
    i18n: 'Service Area',
    resource: 'Hero-service-area',
    action: 'read',
  },
  {
    route: 'hero-recover',
    title: 'hero-recover',
    slug: 'recover',
    icon: 'ClockIcon',
    i18n: 'Recover',
    resource: 'Hero-recover',
    action: 'read',
  },

  // {
  //   route: null,
  //   title: 'hero-logout',
  //   slug: 'logout',
  //   icon: 'HomeIcon',
  //   i18n: 'Logout',
  //   resource: 'Hero-logout',
  //   action: 'read',
  // },
  //
]
