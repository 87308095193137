/* eslint-disable no-use-before-define */
import root from '../../router/root'
import franchise from '../../router/franchise'
import company from '../../router/company'
import delivery from '../../router/services/delivery'
import onework from '../../router/services/onework'
import hero from '../../router/services/hero'

import rootNav from '../../navigation/root'
import franchiseNav from '../../navigation/franchise'
import companyNav from '../../navigation/company'
import deliveryNav from '../../navigation/services/delivery'
import oneworkNav from '../../navigation/services/onework'
import heroNav from '../../navigation/services/hero'

export const rootAbility = () => abilityLoop(root, rootNav)

export const franchiseAbility = () => abilityLoop(franchise, franchiseNav)

export const companyAbility = () => abilityLoop(company, companyNav)

export const deliveryAbility = () => abilityLoop(delivery, deliveryNav)

export const oneworkAbility = () => abilityLoop(onework, oneworkNav)

export const heroAbility = () => abilityLoop(hero, heroNav)

export const initialAbility = []

const dontShowFirst = [
  // Driver Delivery && Driver Call
  'Hero-setup-delivery',
  // Store Grocery
  'Hero-grocery-monitor',
  // Store Food
  'Hero-food-monitor',
  'Hero-rating-store-food',
  // Store Queue
  'Hero-queue-system',
  'Hero-queue-monitor',
  'Hero-list-service-queue',
  'Hero-queue-creation-date',
  'Hero-rating-store-queue',
  'Hero-queue-template-details',
  // Driver Delivery
  'Hero-delivery-system',
  'Hero-delivery-monitor',
  'Hero-setup-delivery-massenger',
  'Hero-setup-delivery-delivery',
  'Hero-rating-driver-delivery',
  // Driver Call
  'Hero-call-system',
  'Hero-call-monitor',
  'Hero-list-service-call',
  'Hero-driver-type',
  'Hero-setup-delivery-call',
  'Hero-rating-driver-call',
  // Services
  'Hero-services-system',
  'Hero-services-monitor',
  'Hero-rating-services',
  // Live Streaming
  'Hero-live-streaming-system',
  'Hero-live-streaming-system-monitor',
  'Hero-live-streaming-system-monitor',
  // Settings
  'Hero-system-settings',
  'Hero-privilege',
  'Hero-service-area',
]

function abilityLoop(data, dataNav) {
  const resource = []
  const array = []
  const action = ['read', 'create', 'update', 'delete']
  const role = localStorage.getItem('role') ? localStorage.getItem('role') : ''
  const applicationId = localStorage.getItem('applicationId') ? localStorage.getItem('applicationId') : null

  for (let index = 0; index < data.length; index += 1) {
    if (data[index].meta.resource !== 'Auth') {
      if (!resource.includes(data[index].meta.resource)) {
        resource.push(data[index].meta.resource)
        if (role === 'root' && !applicationId) {
          // eslint-disable-next-line no-unused-vars
          action.forEach((item, indexAction) => {
            array.push({ subject: data[index].meta.resource, action: item })
          })
        } else {
          // eslint-disable-next-line no-lonely-if
          if (!dontShowFirst.includes(data[index].meta.resource)) {
            // eslint-disable-next-line no-unused-vars
            action.forEach((item, indexAction) => {
              array.push({ subject: data[index].meta.resource, action: item })
            })
          } else if (!pageGet(data[index].meta.resource)) {
            // eslint-disable-next-line no-unused-vars
            action.forEach((item, indexAction) => {
              array.push({ subject: data[index].meta.resource, action: item })
            })
          }
        }
      }
    }
  }
  for (let index = 0; index < dataNav.length; index += 1) {
    if (dataNav[index].resource !== 'Auth') {
      if (!resource.includes(dataNav[index].resource)) {
        if (Array.isArray(dataNav[index].children)) {
          for (let indexResource = 0; indexResource < dataNav[index].children.length; indexResource += 1) {
            if (!resource.includes(dataNav[index].children[indexResource].resource)) {
              resource.push(dataNav[index].children[indexResource].resource)
            }
          }
        }
        resource.push(dataNav[index].resource)
        if (role === 'root' && !applicationId) {
          // eslint-disable-next-line no-unused-vars
          action.forEach((item, indexAction) => {
            array.push({ subject: dataNav[index].resource, action: item })
          })
        } else {
          // eslint-disable-next-line no-lonely-if
          if (!dontShowFirst.includes(dataNav[index].resource)) {
            // eslint-disable-next-line no-unused-vars
            action.forEach((item, indexAction) => {
              array.push({ subject: dataNav[index].resource, action: item })
            })
          } else if (!pageGet(dataNav[index].resource)) {
            // eslint-disable-next-line no-unused-vars
            action.forEach((item, indexAction) => {
              array.push({ subject: dataNav[index].resource, action: item })
            })
          }
        }
        if (Array.isArray(dataNav[index].children)) {
          for (let indexResource = 0; indexResource < dataNav[index].children.length; indexResource += 1) {
            if (role === 'root' && !applicationId) {
              // eslint-disable-next-line no-unused-vars
              action.forEach((item, indexAction) => {
                array.push({ subject: dataNav[index].children[indexResource].resource, action: item })
              })
            } else {
              // eslint-disable-next-line no-lonely-if
              if (!dontShowFirst.includes(dataNav[index].children[indexResource].resource)) {
                // eslint-disable-next-line no-unused-vars
                action.forEach((item, indexAction) => {
                  array.push({ subject: dataNav[index].children[indexResource].resource, action: item })
                })
              } else if (!pageGet(dataNav[index].children[indexResource].resource)) {
                // eslint-disable-next-line no-unused-vars
                action.forEach((item, indexAction) => {
                  array.push({ subject: dataNav[index].children[indexResource].resource, action: item })
                })
              }
            }
          }
        }
      }
    }
  }
  return array
}

// eslint-disable-next-line no-unused-vars
function pageGet(data) {
  const role = localStorage.getItem('role') ? localStorage.getItem('role') : ''
  let systemOption = localStorage.getItem('systemOption') ? localStorage.getItem('systemOption') : ''
  systemOption = systemOption.split(',')
  let status = true
  let page = []

  if (role === 'root' || role === 'franchise') {
    const pageIn = [
      'Hero-system-settings',
      'Hero-service-area',
    ]
    page = page.concat(pageIn)
  }
  if (systemOption.includes('store-grocery')) {
    const pageIn = [
      'Hero-grocery-monitor',
    ]
    page = page.concat(pageIn)
  }

  if (systemOption.includes('store-food')) {
    const pageIn = [
      'Hero-food-monitor',
      'Hero-rating-store-food',
    ]
    page = page.concat(pageIn)
  }

  if (systemOption.includes('store-queue')) {
    const pageIn = [
      'Hero-queue-system',
      'Hero-queue-monitor',
      'Hero-list-service-queue',
      'Hero-queue-creation-date',
      'Hero-rating-store-queue',
      'Hero-queue-template-details',
    ]
    page = page.concat(pageIn)
  }

  if (systemOption.includes('driver-delivery')) {
    const pageIn = [
      'Hero-setup-delivery',
      'Hero-delivery-system',
      'Hero-delivery-monitor',
      'Hero-setup-delivery-massenger',
      'Hero-setup-delivery-delivery',
      'Hero-rating-driver-delivery',
    ]
    page = page.concat(pageIn)
  }

  if (systemOption.includes('driver-call')) {
    const pageIn = [
      'Hero-setup-delivery',
      'Hero-call-system',
      'Hero-call-monitor',
      'Hero-list-service-call',
      'Hero-driver-type',
      'Hero-setup-delivery-call',
      'Hero-rating-driver-call',
    ]
    page = page.concat(pageIn)
  }

  if (systemOption.includes('services')) {
    const pageIn = [
      'Hero-services-system',
      'Hero-services-monitor',
      'Hero-rating-services',
    ]
    page = page.concat(pageIn)
  }

  if (systemOption.includes('liveStreaming')) {
    const pageIn = [
      'Hero-live-streaming-system',
      'Hero-live-streaming-system-monitor',
    ]
    page = page.concat(pageIn)
  }

  for (let index = 0; index < page.length; index += 1) {
    if (page[index] === data) {
      status = false
      break
    }
  }

  return status
}

export const _ = undefined
