export default [
  {
    path: '/select/company',
    name: 'select-company',
    component: () => import('../views/company/selectCompany/SelectCompany.vue'),
    meta: {
      pageTitle: 'Select Company',
      layout: 'full',
      action: 'read',
      service: 'Auth',
      resource: 'Auth',
    },
  },
  {
    path: '/company',
    meta: {
      pageTitle: 'Dashboard',
      service: 'company',
      resource: 'Company-dashboard',
      action: 'read',
    },
    redirect: { name: 'company-dashboard' },
  },
  {
    path: '/company/dashboard',
    name: 'company-dashboard',
    component: () => import('../views/company/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      service: 'company',
      resource: 'Company-dashboard',
      action: 'read',
    },
  },
  {
    path: '/company/users/ability/:id',
    name: 'company-users-ability',
    component: () => import('../views/company/users/ability/Settings.vue'),
    meta: {
      pageTitle: 'Users Ability',
      service: 'company',
      resource: 'Company-users-ability',
      action: 'read',
      breadcrumb: [
        {
          text: 'Staff',
          to: '/company/users/staff',
        },
        {
          text: 'Ability',
          active: true,
        },
      ],
      navActiveLink: 'company-users-staff',
    },
  },
  {
    path: '/company/users/admin',
    name: 'company-users-admin',
    component: () => import('../views/company/users/Users.vue'),
    meta: {
      pageTitle: 'Users Admin',
      service: 'company',
      resource: 'Company-users-admin',
      action: 'read',

    },
  },
  {
    path: '/company/users/staff',
    name: 'company-users-staff',
    component: () => import('../views/company/users/Users.vue'),
    meta: {
      pageTitle: 'Users Staff',
      service: 'company',
      resource: 'Company-users-staff',
      action: 'read',

    },
  },
  {
    path: '/company/application',
    name: 'company-application',
    component: () => import('../views/company/application/Application.vue'),
    meta: {
      pageTitle: 'Application',
      service: 'company',
      resource: 'Company-application',
      action: 'read',
    },
  },
]
