export default [
  {
    route: 'franchise-dashboard',
    title: 'franchise-dashboard',
    slug: 'dashboard',
    icon: 'HomeIcon',
    i18n: 'Dashboard',
    resource: 'Franchise-dashboard',
    action: 'read',
  },
  {
    route: null,
    title: 'franchise-users',
    slug: 'users',
    icon: 'UsersIcon',
    i18n: 'Users',
    resource: 'Franchise-users',
    children: [
      {
        route: 'franchise-users-admin',
        title: 'franchise-users-admin',
        slug: 'userAdmin',
        icon: 'BriefcaseIcon',
        i18n: 'Admin',
        resource: 'Franchise-users-admin',
        action: 'read',
      },
      {
        route: 'franchise-users-staff',
        title: 'franchise-users-staff',
        slug: 'userStaff',
        icon: 'PackageIcon',
        i18n: 'Staff',
        resource: 'Franchise-users-admin',
        action: 'read',
      },
    ],
  },
  {
    route: 'franchise-select-package-services',
    title: 'franchise-select-package-services',
    slug: 'select-package-services',
    icon: 'BoxIcon',
    i18n: 'Select Package Services',
    resource: 'Franchise-select-package-services',
    action: 'read',
  },
  {
    route: 'franchise-create-package-application',
    title: 'franchise-create-package-application',
    slug: 'create-package-application',
    icon: 'PackageIcon',
    i18n: 'Create Package Application',
    resource: 'Franchise-create-package-application',
    action: 'read',
  },
  {
    route: 'franchise-company',
    title: 'franchise-company',
    slug: 'company',
    icon: 'HomeIcon',
    i18n: 'Company',
    resource: 'Franchise-company',
    action: 'read',
  },
  {
    route: 'franchise-application',
    title: 'franchise-application',
    slug: 'application',
    icon: 'PocketIcon',
    i18n: 'Application',
    resource: 'Franchise-application',
    action: 'read',
  },
]
