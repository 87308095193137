export default [
  {
    path: '/onework',
    meta: {
      service: 'onework',
      resource: 'Onework-dashboard',
      action: 'read',
    },
    redirect: { name: 'onework-dashboard' },
  },
  {
    path: '/onework/dashboard',
    name: 'onework-dashboard',
    component: () => import('../../views/services/onework/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      service: 'onework',
      resource: 'Onework-dashboard',
      action: 'read',
    },
  },
  {
    path: '/onework/users/ability/:id',
    name: 'onework-users-ability',
    component: () => import('../../views/services/onework/users/ability/Settings.vue'),
    meta: {
      pageTitle: 'Users Ability',
      service: 'onework',
      resource: 'Onework-users-ability',
      action: 'read',
      breadcrumb: [
        {
          text: 'Staff',
          to: '/onework/users/staff',
        },
        {
          text: 'Ability',
          active: true,
        },
      ],
      navActiveLink: 'onework-users-staff',
    },
  },
  {
    path: '/onework/users/admin',
    name: 'onework-users-admin',
    component: () => import('../../views/services/onework/users/Users.vue'),
    meta: {
      pageTitle: 'Users Admin',
      service: 'onework',
      resource: 'Onework-users-admin',
      action: 'read',
    },
  },
  {
    path: '/onework/users/staff',
    name: 'onework-users-staff',
    component: () => import('../../views/services/onework/users/Users.vue'),
    meta: {
      pageTitle: 'Users Staff',
      service: 'onework',
      resource: 'Onework-users-staff',
      action: 'read',
    },
  },
]
