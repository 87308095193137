export default [
  {
    path: '/hero',
    meta: {
      service: 'hero',
      resource: 'Hero-dashboard',
      action: 'read',
    },
    redirect: { name: 'Hero-dashboard' },
  },
  {
    path: '/hero/dashboard',
    name: 'hero-dashboard',
    component: () => import('../../views/services/hero/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      service: 'hero',
      resource: 'Hero-dashboard',
      action: 'read',
    },
  },
  {
    path: '/hero/grocery/monitor',
    name: 'hero-grocery-monitor',
    component: () => import('../../views/services/hero/grocerySystem/groceryMonitor/GroceryMonitor.vue'),
    meta: {
      pageTitle: 'Grocery Monitor',
      service: 'hero',
      resource: 'Hero-grocery-monitor',
      action: 'read',
    },
  },
  {
    path: '/hero/food/monitor',
    name: 'hero-food-monitor',
    component: () => import('../../views/services/hero/foodSystem/foodMonitor/FoodMonitor.vue'),
    meta: {
      pageTitle: 'Food Monitor',
      service: 'hero',
      resource: 'Hero-food-monitor',
      action: 'read',
    },
  },
  {
    path: '/hero/queue/monitor',
    name: 'hero-queue-monitor',
    component: () => import('../../views/services/hero/queueSystem/queueMonitor/QueueMonitor.vue'),
    meta: {
      pageTitle: 'Queue Monitor',
      service: 'hero',
      resource: 'Hero-queue-monitor',
      action: 'read',
    },
  },
  {
    path: '/hero/list/service/queue',
    name: 'hero-list-service-queue',
    component: () => import('../../views/services/hero/queueSystem/listServiceQueue/ListServiceQueue.vue'),
    meta: {
      pageTitle: 'List Service Queue',
      service: 'hero',
      resource: 'Hero-list-service-queue',
      action: 'read',
    },
  },
  {
    path: '/hero/queue/creation/date',
    name: 'hero-queue-creation-date',
    component: () => import('../../views/services/hero/queueSystem/queueCreationDate/QueueCreationDate.vue'),
    meta: {
      pageTitle: 'Queue Creation Date',
      service: 'hero',
      resource: 'Hero-queue-creation-date',
      action: 'read',
    },
  },
  // {
  //   path: '/hero/queue/template/details',
  //   name: 'hero-queue-template-details',
  //   component: () => import('../../views/services/hero/queueSystem/queuetemplateDetails/QueueTemplateDetails.vue'),
  //   meta: {
  //     pageTitle: 'Queue Template Details',
  //     service: 'hero',
  //     resource: 'Hero-queue-template-details',
  //     action: 'read',
  //   },
  // },
  // {
  //   path: '/hero/queue/template/details/:id',
  //   name: 'hero-queue-template-details-view',
  //   component: () => import('../../views/services/hero/queueSystem/queuetemplateDetails/QueueTemplateDetailView.vue'),
  //   meta: {
  //     pageTitle: 'Queue Template Details View',
  //     service: 'hero',
  //     resource: 'Hero-queue-template-details-view',
  //     action: 'read',
  //     breadcrumb: [
  //       {
  //         text: 'Queue Template Details',
  //         to: '/hero/queue/template/details',
  //       },
  //       {
  //         text: 'View',
  //         active: true,
  //       },
  //     ],
  //     navActiveLink: 'hero-queue-template-details',
  //   },
  // },
  {
    path: '/hero/product/category',
    name: 'hero-product-category',
    component: () => import('../../views/services/hero/productSystem/productCategory/ProductCategory.vue'),
    meta: {
      pageTitle: 'Product Category',
      service: 'hero',
      resource: 'Hero-product-category',
      action: 'read',
    },
  },
  {
    path: '/hero/product',
    name: 'hero-product',
    component: () => import('../../views/services/hero/productSystem/product/Product.vue'),
    meta: {
      pageTitle: 'Product',
      service: 'hero',
      resource: 'Hero-commerce-product',
      action: 'read',
    },
  },
  {
    path: '/hero/product/view/:id',
    name: 'hero-product-view',
    component: () => import('../../views/services/hero/productSystem/product/ViewProduct.vue'),
    meta: {
      pageTitle: 'Product View',
      service: 'hero',
      resource: 'Hero-product-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Product',
          to: '/hero/product',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'hero-product',
    },
  },
  {
    path: '/hero/product/stock/:id',
    name: 'hero-product-stock',
    component: () => import('../../views/services/hero/productSystem/product/stock/Stock.vue'),
    meta: {
      pageTitle: 'Product Stock',
      service: 'hero',
      resource: 'Hero-product-stock',
      action: 'read',
      breadcrumb: [
        {
          text: 'Product',
          to: '/hero/product',
        },
        {
          text: 'Stock',
          active: true,
        },
      ],
      navActiveLink: 'hero-product',
    },
  },
  {
    path: '/hero/product/managecategory/:id',
    name: 'hero-product-managecategory',
    component: () => import('../../views/services/hero/productSystem/product/manageCategory/ManageCategory.vue'),
    meta: {
      pageTitle: 'Manage Product Categories',
      service: 'hero',
      resource: 'Hero-product-managecategory',
      action: 'read',
      breadcrumb: [
        {
          text: 'Product',
          to: '/hero/product',
        },
        {
          text: 'Categories',
          active: true,
        },
      ],
      navActiveLink: 'hero-product',
    },
  },
  {
    path: '/hero/search/option/continuous/option',
    name: 'hero-search-option-continuous-option',
    component: () => import('../../views/services/hero/searchOption/continuousOption/SearchOption.vue'),
    meta: {
      pageTitle: 'Continuous option',
      service: 'hero',
      resource: 'Hero-search-option-continuous-option',
      action: 'read',
    },
  },
  {
    path: '/hero/search/option/option',
    name: 'hero-search-option-option',
    component: () => import('../../views/services/hero/searchOption/option/Option.vue'),
    meta: {
      pageTitle: 'Option',
      service: 'hero',
      resource: 'Hero-search-option-option',
      action: 'read',
    },
  },
  {
    path: '/hero/template/details',
    name: 'hero-template-details',
    component: () => import('../../views/services/hero/queueSystem/queuetemplateDetails/QueueTemplateDetails.vue'),
    meta: {
      pageTitle: 'Template Details',
      service: 'hero',
      resource: 'Hero-template-details',
      action: 'read',
    },
  },
  {
    path: '/hero/template/details/:id',
    name: 'hero-template-details-view',
    component: () => import('../../views/services/hero/queueSystem/queuetemplateDetails/QueueTemplateDetailView.vue'),
    meta: {
      pageTitle: 'Template Details View',
      service: 'hero',
      resource: 'Hero-template-details-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Template Details',
          to: '/hero/template/details',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'hero-template-details',
    },
  },
  {
    path: '/hero/main/tags',
    name: 'hero-main-tags',
    component: () => import('../../views/services/hero/mainTags/Tags.vue'),
    meta: {
      pageTitle: 'Main Tags',
      service: 'hero',
      resource: 'Hero-main-tags',
      action: 'read',
    },
  },
  {
    path: '/hero/tags',
    name: 'hero-secondary-tags',
    component: () => import('../../views/services/hero/tags/Tags.vue'),
    meta: {
      pageTitle: 'Secondary Tags',
      service: 'hero',
      resource: 'Hero-secondary-tags',
      action: 'read',
    },
  },
  {
    path: '/hero/ranking',
    name: 'hero-ranking',
    component: () => import('../../views/services/hero/ranking/Ranking.vue'),
    meta: {
      pageTitle: 'Ranking',
      service: 'hero',
      resource: 'Hero-ranking',
      action: 'read',
    },
  },
  {
    path: '/hero/ranking/:id',
    name: 'hero-ranking-view',
    component: () => import('../../views/services/hero/ranking/RankingDetailView.vue'),
    meta: {
      pageTitle: 'Ranking View',
      service: 'hero',
      resource: 'Hero-ranking-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Ranking',
          to: '/hero/ranking',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'hero-ranking',
    },
  },
  {
    path: '/hero/delivery/monitor',
    name: 'hero-delivery-monitor',
    component: () => import('../../views/services/hero/deliverySystem/deliveryMonitor/DeliveryMonitor.vue'),
    meta: {
      pageTitle: 'Delivery Monitor',
      service: 'hero',
      resource: 'Hero-delivery-monitor',
      action: 'read',
    },
  },
  {
    path: '/hero/call/monitor',
    name: 'hero-call-monitor',
    component: () => import('../../views/services/hero/callSystem/callMonitor/CallMonitor.vue'),
    meta: {
      pageTitle: 'Call Monitor',
      service: 'hero',
      resource: 'Hero-call-monitor',
      action: 'read',
    },
  },
  {
    path: '/hero/list/service/call',
    name: 'hero-list-service-call',
    component: () => import('../../views/services/hero/callSystem/listServiceCall/ListServiceCall.vue'),
    meta: {
      pageTitle: 'List Service Call',
      service: 'hero',
      resource: 'Hero-list-service-call',
      action: 'read',
    },
  },
  {
    path: '/hero/driver/type',
    name: 'hero-driver-type',
    component: () => import('../../views/services/hero/callSystem/driverType/DriverType.vue'),
    meta: {
      pageTitle: 'Driver Type',
      service: 'hero',
      resource: 'Hero-driver-type',
      action: 'read',
    },
  },
  {
    path: '/hero/services/monitor',
    name: 'hero-services-monitor',
    component: () => import('../../views/services/hero/servicesSystem/servicesMonitor/ServicesMonitor.vue'),
    meta: {
      pageTitle: 'Services Monitor',
      service: 'hero',
      resource: 'Hero-services-monitor',
      action: 'read',
    },
  },
  {
    path: '/hero/live/streaming/monitor',
    name: 'hero-live-streaming-system-monitor',
    component: () => import('../../views/services/hero/liveStreamingSystem/liveStreamingSystemMonitor/LiveStreamingSystemMonitor.vue'),
    meta: {
      pageTitle: 'Live Streaming System Monitor',
      service: 'hero',
      resource: 'Hero-live-streaming-system-monitor',
      action: 'read',
    },
  },
  {
    path: '/hero/users/ability/:id',
    name: 'hero-users-ability',
    component: () => import('../../views/services/hero/users/ability/Settings.vue'),
    meta: {
      pageTitle: 'Users Ability',
      service: 'hero',
      resource: 'Hero-users-ability',
      action: 'read',
      breadcrumb: [
        {
          text: 'Staff',
          to: '/hero/users/staff',
        },
        {
          text: 'Ability',
          active: true,
        },
      ],
      navActiveLink: 'hero-users-staff',
    },
  },
  {
    path: '/hero/users/admin',
    name: 'hero-users-admin',
    component: () => import('../../views/services/hero/users/Users.vue'),
    meta: {
      pageTitle: 'Users Admin',
      service: 'hero',
      resource: 'Hero-users-admin',
      action: 'read',
    },
  },
  {
    path: '/hero/users/staff',
    name: 'hero-users-staff',
    component: () => import('../../views/services/hero/users/Users.vue'),
    meta: {
      pageTitle: 'Users Staff',
      service: 'hero',
      resource: 'Hero-users-staff',
      action: 'read',
    },
  },
  {
    path: '/hero/partner/package',
    name: 'hero-partner-package',
    component: () => import('../../views/services/hero/partner/package/Package.vue'),
    meta: {
      pageTitle: 'Partner Package',
      service: 'hero',
      resource: 'Hero-partner-package',
      action: 'read',
    },
  },
  {
    path: '/hero/partner/package/:serviceType',
    name: 'hero-partner-package-type',
    component: () => import('../../views/services/hero/partner/package/open/Package.vue'),
    meta: {
      pageTitle: 'Partner Package Type',
      service: 'hero',
      resource: 'Hero-partner-package-type',
      action: 'read',
      breadcrumb: [
        {
          text: 'Partner Package',
          to: '/hero/partner/package',
        },
        {
          text: 'Type',
          active: true,
        },
      ],
      navActiveLink: 'hero-partner-package',
    },
  },
  {
    path: '/hero/partner/package/:serviceType/:id',
    name: 'hero-partner-package-type-view',
    component: () => import('../../views/services/hero/partner/package/open/PackageView.vue'),
    meta: {
      pageTitle: 'Partner Package Type',
      service: 'hero',
      resource: 'Hero-partner-package-type-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Partner Package',
          to: '/hero/partner/package',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'hero-partner-package',
    },
  },
  {
    path: '/hero/partner/type',
    name: 'hero-partner-type',
    component: () => import('../../views/services/hero/partner/type/Type.vue'),
    meta: {
      pageTitle: 'Partner Type',
      service: 'hero',
      resource: 'Hero-partner-type',
      action: 'read',
    },
  },
  {
    path: '/hero/partner/type/:serviceTypeId/:serviceType',
    name: 'hero-partner-type-type',
    component: () => import('../../views/services/hero/partner/type/open/Type.vue'),
    meta: {
      pageTitle: 'Partner Type',
      service: 'hero',
      resource: 'Hero-partner-type-type',
      action: 'read',
      breadcrumb: [
        {
          text: 'Partner Type',
          to: '/hero/partner/type',
        },
        {
          text: 'Type',
          active: true,
        },
      ],
      navActiveLink: 'hero-partner-type',
    },
  },
  {
    path: '/hero/partner/type/:serviceTypeId/:serviceType/:id',
    name: 'hero-partner-type-type-view',
    component: () => import('../../views/services/hero/partner/type/open/TypeView.vue'),
    meta: {
      pageTitle: 'Partner Type View',
      service: 'hero',
      resource: 'Hero-partner-type-type-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Partner Type',
          to: '/hero/partner/type',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'hero-partner-type',
    },
  },
  {
    path: '/hero/partner/users',
    name: 'hero-partner-users',
    component: () => import('../../views/services/hero/partner/users/Users.vue'),
    meta: {
      pageTitle: 'Partner Users',
      service: 'hero',
      resource: 'Hero-partner-users',
      action: 'read',
    },
  },
  {
    path: '/hero/partner/template/time',
    name: 'hero-partner-template-time',
    component: () => import('../../views/services/hero/partner/templateTime/TemplateTime.vue'),
    meta: {
      pageTitle: 'Partner Template Time',
      service: 'hero',
      resource: 'Hero-partner-template-time',
      action: 'read',
    },
  },
  {
    path: '/hero/partner/template/time/:id',
    name: 'hero-partner-template-time-view',
    component: () => import('../../views/services/hero/partner/templateTime/TemplateTimeView.vue'),
    meta: {
      pageTitle: 'Partner Template Time View',
      service: 'hero',
      resource: 'Hero-partner-template-time-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Partner Template Time',
          to: '/hero/partner/template/time',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'hero-partner-template-time',
    },
  },
  {
    path: '/hero/partner/privileges/coupon',
    name: 'hero-partner-privileges-coupon',
    component: () => import('../../views/services/hero/partnerPrivileges/coupon/Index.vue'),
    meta: {
      pageTitle: 'Coupon',
      service: 'hero',
      resource: 'Hero-partner-privileges-coupon',
      action: 'read',
    },
  },
  {
    path: '/hero/partner/privileges/coupon/:id',
    name: 'hero-partner-privileges-coupon-view',
    component: () => import('../../views/services/hero/partnerPrivileges/coupon/Index.vue'),
    meta: {
      pageTitle: 'Coupon',
      service: 'hero',
      resource: 'Hero-partner-privileges-coupon-view',
      action: 'read',
      navActiveLink: 'hero-partner-privileges-coupon',
    },
  },
  {
    path: '/hero/partner/privileges/promotion',
    name: 'hero-partner-privileges-promotion',
    component: () => import('../../views/services/hero/partnerPrivileges/promotion/Index.vue'),
    meta: {
      pageTitle: 'Promotion',
      service: 'hero',
      resource: 'Hero-partner-privileges-promotion',
      action: 'read',
    },
  },
  {
    path: '/hero/partner/privileges/promotion/:id',
    name: 'hero-partner-privileges-promotion-view',
    component: () => import('../../views/services/hero/partnerPrivileges/promotion/Index.vue'),
    meta: {
      pageTitle: 'Promotion',
      service: 'hero',
      resource: 'Hero-partner-privileges-promotion-view',
      action: 'read',
      navActiveLink: 'hero-partner-privileges-promotion',
    },
  },
  {
    path: '/hero/partner/privileges/promotion/service/:id',
    name: 'hero-partner-privileges-service-in-promotion',
    component: () => import('../../views/services/hero/partnerPrivileges/promotion/ServiceInPromotion.vue'),
    meta: {
      pageTitle: 'Service In Promotion',
      service: 'hero',
      resource: 'Hero-partner-privileges-service-in-promotion',
      action: 'read',
      breadcrumb: [
        {
          text: 'Promotion',
          to: '/hero/partner/privileges/promotion',
        },
        {
          text: 'Service In Promotion',
          active: true,
        },
      ],
      navActiveLink: 'hero-partner-privileges-promotion',
    },
  },
  {
    path: '/hero/package/purchase',
    name: 'hero-package-purchase',
    component: () => import('../../views/services/hero/packagePurchase/PackagePurchase.vue'),
    meta: {
      pageTitle: 'Package Purchase',
      service: 'hero',
      resource: 'Hero-package-purchase',
      action: 'read',
    },
  },
  {
    path: '/hero/manage/service/type',
    name: 'hero-service-type',
    component: () => import('../../views/services/hero/manageService/serviceType/ServiceType.vue'),
    meta: {
      pageTitle: 'Service Type',
      service: 'hero',
      resource: 'Hero-service-type',
      action: 'read',
    },
  },
  {
    path: '/hero/manage/service/type/sort',
    name: 'hero-service-type-sort',
    component: () => import('../../views/services/hero/manageService/serviceType/Sort.vue'),
    meta: {
      pageTitle: 'Service Type Sort',
      service: 'hero',
      resource: 'Hero-service-type-sort',
      action: 'read',
      breadcrumb: [
        {
          text: 'Service Type',
          to: '/hero/manage/service/type',
        },
        {
          text: 'Service Type Sort',
          active: true,
        },
      ],
      navActiveLink: 'hero-service-type',
    },
  },
  {
    path: '/hero/manage/service/subtype/:id',
    name: 'hero-service-subtype',
    component: () => import('../../views/services/hero/manageService/serviceSubType/ServiceSubType.vue'),
    meta: {
      pageTitle: 'Service SubType',
      service: 'hero',
      resource: 'Hero-service-subtype',
      action: 'read',
    },
  },
  {
    path: '/hero/manage/service',
    name: 'hero-service',
    component: () => import('../../views/services/hero/manageService/service/Service.vue'),
    meta: {
      pageTitle: 'Service',
      service: 'hero',
      resource: 'Hero-service',
      action: 'read',
    },
  },
  {
    path: '/hero/manage/service/sort',
    name: 'hero-service-sort',
    component: () => import('../../views/services/hero/manageService/service/Sort.vue'),
    meta: {
      pageTitle: 'Service Sort',
      service: 'hero',
      resource: 'Hero-service-sort',
      action: 'read',
      breadcrumb: [
        {
          text: 'Service',
          to: '/hero/manage/service',
        },
        {
          text: 'Service Sort',
          active: true,
        },
      ],
      navActiveLink: 'hero-service',
    },
  },
  {
    path: '/hero/setup/delivery/call',
    name: 'hero-setup-delivery-call',
    component: () => import('@/views/services/hero/setupDelivery/SetupDelivery.vue'),
    meta: {
      pageTitle: 'Setup Delivery Call',
      service: 'hero',
      resource: 'Hero-setup-delivery-call',
      action: 'read',
    },
  },
  {
    path: '/hero/setup/delivery/massenger',
    name: 'hero-setup-delivery-massenger',
    component: () => import('@/views/services/hero/setupDelivery/SetupDelivery.vue'),
    meta: {
      pageTitle: 'Setup Delivery Massenger',
      service: 'hero',
      resource: 'Hero-setup-delivery-massenger',
      action: 'read',
    },
  },
  {
    path: '/hero/setup/delivery/delivery',
    name: 'hero-setup-delivery-delivery',
    component: () => import('@/views/services/hero/setupDelivery/SetupDelivery.vue'),
    meta: {
      pageTitle: 'Setup Delivery',
      service: 'hero',
      resource: 'Hero-setup-delivery-delivery',
      action: 'read',
    },
  },
  {
    path: '/hero/setup/delivery/call/:id',
    name: 'hero-setup-delivery-call-view',
    component: () => import('@/views/services/hero/setupDelivery/SetupDeliveryView.vue'),
    meta: {
      pageTitle: 'Setup Delivery Call View',
      service: 'hero',
      resource: 'Hero-setup-delivery-call-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Setup Delivery',
          to: '/hero/setup/delivery/call',
        },
        {
          text: 'Type View',
          active: true,
        },
      ],
      navActiveLink: 'hero-setup-delivery-call',
    },
  },
  {
    path: '/hero/setup/delivery/massenger/:id',
    name: 'hero-setup-delivery-massenger-view',
    component: () => import('@/views/services/hero/setupDelivery/SetupDeliveryView.vue'),
    meta: {
      pageTitle: 'Setup Delivery Massenger View',
      service: 'hero',
      resource: 'Hero-setup-delivery-massenger-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Setup Delivery',
          to: '/hero/setup/delivery/massenger',
        },
        {
          text: 'Type View',
          active: true,
        },
      ],
      navActiveLink: 'hero-setup-delivery-massenger',
    },
  },
  {
    path: '/hero/setup/delivery/delivery/:id',
    name: 'hero-setup-delivery-delivery-view',
    component: () => import('@/views/services/hero/setupDelivery/SetupDeliveryView.vue'),
    meta: {
      pageTitle: 'Setup Delivery Delivery View',
      service: 'hero',
      resource: 'Hero-setup-delivery-delivery-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Setup Delivery',
          to: '/hero/setup/delivery/delivery',
        },
        {
          text: 'Type View',
          active: true,
        },
      ],
      navActiveLink: 'hero-setup-delivery-delivery',
    },
  },
  {
    path: '/hero/wallet/member',
    name: 'hero-wallet-member',
    component: () => import('../../views/services/hero/wallet/Wallet.vue'),
    meta: {
      pageTitle: 'Wallet Member',
      service: 'hero',
      resource: 'Hero-wallet-member',
      action: 'read',
    },
  },
  {
    path: '/hero/wallet/partner',
    name: 'hero-wallet-partner',
    component: () => import('../../views/services/hero/wallet/Wallet.vue'),
    meta: {
      pageTitle: 'Wallet Partner',
      service: 'hero',
      resource: 'Hero-wallet-partner',
      action: 'read',
    },
  },
  {
    path: '/hero/wallet/balance/member',
    name: 'hero-wallet-balance-member',
    component: () => import('../../views/services/hero/walletBalance/WalletBalance.vue'),
    meta: {
      pageTitle: 'Wallet Balance Member',
      service: 'hero',
      resource: 'Hero-wallet-balance-member',
      action: 'read',
    },
  },
  {
    path: '/hero/wallet/balance/partner',
    name: 'hero-wallet-balance-partner',
    component: () => import('../../views/services/hero/walletBalance/WalletBalance.vue'),
    meta: {
      pageTitle: 'Wallet Balance Partner',
      service: 'hero',
      resource: 'Hero-wallet-balance-partner',
      action: 'read',
    },
  },
  {
    path: '/hero/point/member',
    name: 'hero-point-member',
    component: () => import('../../views/services/hero/point/Point.vue'),
    meta: {
      pageTitle: 'Point Member',
      service: 'hero',
      resource: 'Hero-point-member',
      action: 'read',
    },
  },
  {
    path: '/hero/point/balance/member',
    name: 'hero-point-balance-member',
    component: () => import('../../views/services/hero/pointBalance/PointBalance.vue'),
    meta: {
      pageTitle: 'Point Balance Member',
      service: 'hero',
      resource: 'Hero-point-balance-member',
      action: 'read',
    },
  },
  {
    path: '/hero/privilege/coupon',
    name: 'hero-privilege-coupon',
    component: () => import('../../views/services/hero/privilege/coupon/Index.vue'),
    meta: {
      pageTitle: 'Coupon',
      service: 'hero',
      resource: 'Hero-privilege-coupon',
      action: 'read',
    },
  },
  {
    path: '/hero/privilege/coupon/:id',
    name: 'hero-privilege-coupon-view',
    component: () => import('../../views/services/hero/privilege/coupon/Index.vue'),
    meta: {
      pageTitle: 'Coupon',
      service: 'hero',
      resource: 'Hero-privilege-coupon-view',
      action: 'read',
      navActiveLink: 'hero-privilege-coupon',
    },
  },
  {
    path: '/hero/privilege/promotion',
    name: 'hero-privilege-promotion',
    component: () => import('../../views/services/hero/privilege/promotion/Index.vue'),
    meta: {
      pageTitle: 'Promotion',
      service: 'hero',
      resource: 'Hero-privilege-promotion',
      action: 'read',
    },
  },
  {
    path: '/hero/privilege/promotion/:id',
    name: 'hero-privilege-promotion-view',
    component: () => import('../../views/services/hero/privilege/promotion/Index.vue'),
    meta: {
      pageTitle: 'Promotion',
      service: 'hero',
      resource: 'Hero-privilege-promotion-view',
      action: 'read',
      navActiveLink: 'hero-privilege-promotion',
    },
  },
  {
    path: '/hero/privilege/promotion/service/:id',
    name: 'hero-privilege-service-in-promotion',
    component: () => import('../../views/services/hero/privilege/promotion/ServiceInPromotion.vue'),
    meta: {
      pageTitle: 'Service In Promotion',
      service: 'hero',
      resource: 'Hero-privilege-service-in-promotion',
      action: 'read',
      breadcrumb: [
        {
          text: 'Promotion',
          to: '/hero/privilege/promotion',
        },
        {
          text: 'Service In Promotion',
          active: true,
        },
      ],
      navActiveLink: 'hero-privilege-promotion',
    },
  },
  {
    path: '/hero/member',
    name: 'hero-member',
    component: () => import('../../views/services/hero/member/Member.vue'),
    meta: {
      pageTitle: 'Member',
      service: 'hero',
      resource: 'Hero-member',
      action: 'read',
    },
  },
  {
    path: '/hero/member/config',
    name: 'hero-member-config',
    component: () => import('../../views/services/hero/member/ConfigAutoGroupMember.vue'),
    meta: {
      pageTitle: 'Config Member',
      service: 'hero',
      resource: 'Hero-member-config',
      action: 'read',
    },
  },
  {
    path: '/hero/rating/store-food',
    name: 'hero-rating-store-food',
    component: () => import('../../views/services/hero/rating/Rating.vue'),
    meta: {
      pageTitle: 'Rating Store Food',
      service: 'hero',
      resource: 'Hero-rating-store-food',
      action: 'read',
    },
  },
  {
    path: '/hero/rating/store-queue',
    name: 'hero-rating-store-queue',
    component: () => import('../../views/services/hero/rating/Rating.vue'),
    meta: {
      pageTitle: 'Rating Store Queue',
      service: 'hero',
      resource: 'Hero-rating-store-queue',
      action: 'read',
    },
  },
  {
    path: '/hero/rating/driver-call',
    name: 'hero-rating-driver-call',
    component: () => import('../../views/services/hero/rating/Rating.vue'),
    meta: {
      pageTitle: 'Rating Driver Call',
      service: 'hero',
      resource: 'Hero-rating-driver-call',
      action: 'read',
    },
  },
  {
    path: '/hero/rating/driver-delivery',
    name: 'hero-rating-driver-delivery',
    component: () => import('../../views/services/hero/rating/Rating.vue'),
    meta: {
      pageTitle: 'Rating Driver Delivery',
      service: 'hero',
      resource: 'Hero-rating-driver-delivery',
      action: 'read',
    },
  },
  {
    path: '/hero/rating/services',
    name: 'hero-rating-services',
    component: () => import('../../views/services/hero/rating/Rating.vue'),
    meta: {
      pageTitle: 'Rating Services',
      service: 'hero',
      resource: 'Hero-rating-services',
      action: 'read',
    },
  },
  {
    path: '/hero/support',
    name: 'hero-support',
    component: () => import('../../views/services/hero/support/SupportList.vue'),
    meta: {
      pageTitle: 'Support',
      service: 'hero',
      resource: 'Hero-support',
      action: 'read',
    },
  },
  {
    path: '/hero/chat/:id',
    name: 'hero-chat-view',
    component: () => import('../../views/services/hero/support/Chat.vue'),
    meta: {
      pageTitle: 'Chat View',
      service: 'hero',
      resource: 'Hero-chat-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Support',
          to: '/hero/support',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'hero-support',
    },
  },
  {
    path: '/hero/chatlist',
    name: 'hero-chat-list',
    component: () => import('../../views/services/hero/chat/ChatList.vue'),
    meta: {
      pageTitle: 'Chat',
      service: 'hero',
      resource: 'Hero-chat-list',
      action: 'read',
    },
  },
  {
    path: '/hero/chatlist/:id',
    name: 'hero-chatlist-view',
    component: () => import('../../views/services/hero/chat/Chat.vue'),
    meta: {
      pageTitle: 'Chat View',
      service: 'hero',
      resource: 'Hero-chatlist-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Chat',
          to: '/hero/chatlist',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'hero-chat-list',
    },
  },
  {
    path: '/hero/documents/bank',
    name: 'hero-documents-bank',
    component: () => import('../../views/services/hero/documents/bank/Bank.vue'),
    meta: {
      pageTitle: 'Documents Bank',
      service: 'hero',
      resource: 'Hero-documents-bank',
      action: 'read',
    },
  },
  {
    path: '/hero/document',
    name: 'hero-document',
    component: () => import('../../views/services/hero/documents/document/Document.vue'),
    meta: {
      pageTitle: 'Document',
      service: 'hero',
      resource: 'Hero-document',
      action: 'read',
    },
  },
  {
    path: '/hero/in-application/ads',
    name: 'hero-in-application-ads',
    component: () => import('../../views/services/hero/inApplication/ads/Ads.vue'),
    meta: {
      pageTitle: 'Ads',
      service: 'hero',
      resource: 'Hero-in-application-ads',
      action: 'read',
    },
  },
  {
    path: '/hero/in-application/ads/view/:id',
    name: 'hero-in-application-ads-view',
    component: () => import('../../views/services/hero/inApplication/ads/html/Html.vue'),
    meta: {
      pageTitle: 'Ads View',
      service: 'hero',
      resource: 'Hero-in-application-ads-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Ads',
          to: '/hero/in-application/ads',
        },
        {
          text: 'Ads View',
          active: true,
        },
      ],
      navActiveLink: 'hero-in-application-ads',
    },
  },
  {
    path: '/hero/in-application/ads/sort',
    name: 'hero-in-application-ads-sort',
    component: () => import('../../views/services/hero/inApplication/ads/Sort.vue'),
    meta: {
      pageTitle: 'Ads Sort',
      service: 'hero',
      resource: 'Hero-in-application-ads-sort',
      action: 'read',
      breadcrumb: [
        {
          text: 'Ads',
          to: '/hero/in-application/ads',
        },
        {
          text: 'Ads Sort',
          active: true,
        },
      ],
      navActiveLink: 'hero-in-application-ads',
    },
  },
  {
    path: '/hero/in-application/slide',
    name: 'hero-in-application-slide',
    component: () => import('../../views/services/hero/inApplication/slide/Slide.vue'),
    meta: {
      pageTitle: 'Slide',
      service: 'hero',
      resource: 'Hero-in-application-slide',
      action: 'read',
    },
  },
  {
    path: '/hero/in-application/slide/view/:id',
    name: 'hero-in-application-slide-view',
    component: () => import('../../views/services/hero/inApplication/slide/html/Html.vue'),
    meta: {
      pageTitle: 'Slide View',
      service: 'hero',
      resource: 'Hero-in-application-slide-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Slide',
          to: '/hero/in-application/slide',
        },
        {
          text: 'Slide View',
          active: true,
        },
      ],
      navActiveLink: 'hero-in-application-slide',
    },
  },
  {
    path: '/hero/in-application/slide/sort',
    name: 'hero-in-application-slide-sort',
    component: () => import('../../views/services/hero/inApplication/slide/Sort.vue'),
    meta: {
      pageTitle: 'Slide Sort',
      service: 'hero',
      resource: 'Hero-in-application-slide-sort',
      action: 'read',
      breadcrumb: [
        {
          text: 'Slide',
          to: '/hero/in-application/slide',
        },
        {
          text: 'Slide Sort',
          active: true,
        },
      ],
      navActiveLink: 'hero-in-application-slide',
    },
  },
  {
    path: '/hero/images/settings',
    name: 'hero-images-settings',
    component: () => import('../../views/services/hero/imagesSettings/ImagesSetting.vue'),
    meta: {
      pageTitle: 'Images Settings',
      service: 'hero',
      resource: 'Hero-images-settings',
      action: 'read',
    },
  },
  {
    path: '/hero/images/settings/logo',
    name: 'hero-images-settings-logo',
    component: () => import('../../views/services/hero/imagesSettings/logo/Logo.vue'),
    meta: {
      pageTitle: 'Logo Setting',
      service: 'hero',
      resource: 'Hero-images-settings-logo',
      action: 'read',
      breadcrumb: [
        {
          text: 'Images Setting',
          to: '/hero/images/settings',
        },
        {
          text: 'Logo Setting',
          active: true,
        },
      ],
      navActiveLink: 'hero-images-settings',
    },
  },
  {
    path: '/hero/images/settings/graphics/store',
    name: 'hero-images-settings-graphics-store',
    component: () => import('../../views/services/hero/imagesSettings/graphicsStore/GraphicsStore.vue'),
    meta: {
      pageTitle: 'Graphics Store',
      service: 'hero',
      resource: 'Hero-images-settings-graphics-store',
      action: 'read',
      breadcrumb: [
        {
          text: 'Images Setting',
          to: '/hero/images/settings',
        },
        {
          text: 'Graphics Store Setting',
          active: true,
        },
      ],
      navActiveLink: 'hero-images-settings',
    },
  },
  {
    path: '/hero/images/settings/splash',
    name: 'hero-images-settings-splash',
    component: () => import('../../views/services/hero/imagesSettings/splashScreen/SplashScreen.vue'),
    meta: {
      pageTitle: 'Splash Setting',
      service: 'hero',
      resource: 'Hero-images-settings-splash',
      action: 'read',
      breadcrumb: [
        {
          text: 'Images Setting',
          to: '/hero/images/settings',
        },
        {
          text: 'Splash Setting',
          active: true,
        },
      ],
      navActiveLink: 'hero-images-settings',
    },
  },
  {
    path: '/hero/images/settings/home/background',
    name: 'hero-images-settings-home-background',
    component: () => import('../../views/services/hero/imagesSettings/homeBackground/HomeBackground.vue'),
    meta: {
      pageTitle: 'Home Background',
      service: 'hero',
      resource: 'Hero-images-settings-home-background',
      action: 'read',
      breadcrumb: [
        {
          text: 'Images Setting',
          to: '/hero/images/settings',
        },
        {
          text: 'Home Background',
          active: true,
        },
      ],
      navActiveLink: 'hero-images-settings',
    },
  },
  {
    path: '/hero/images/settings/slide',
    name: 'hero-images-settings-slide',
    component: () => import('../../views/services/hero/imagesSettings/slideScreen/SlideScreen.vue'),
    meta: {
      pageTitle: 'Slide Setting',
      service: 'hero',
      resource: 'Hero-images-settings-slide',
      action: 'read',
      breadcrumb: [
        {
          text: 'Images Setting',
          to: '/hero/images/settings',
        },
        {
          text: 'Slide Setting',
          active: true,
        },
      ],
      navActiveLink: 'hero-images-settings',
    },
  },
  {
    path: '/hero/manage/applications',
    name: 'hero-manage-applications',
    component: () => import('../../views/services/hero/manageApplications/ManageApplications.vue'),
    meta: {
      pageTitle: 'Manage Applications',
      service: 'hero',
      resource: 'Hero-manage-applications',
      action: 'read',
    },
  },
  {
    path: '/hero/manage/bank',
    name: 'hero-manage-bank',
    component: () => import('../../views/services/hero/manageBank/ManageBank.vue'),
    meta: {
      pageTitle: 'Manage Bank',
      service: 'hero',
      resource: 'Hero-manage-bank',
      action: 'read',
    },
  },
  {
    path: '/hero/system/settings',
    name: 'hero-system-settings',
    component: () => import('../../views/services/hero/systemSettings/SystemSettings.vue'),
    meta: {
      pageTitle: 'System Settings',
      service: 'hero',
      resource: 'Hero-system-settings',
      action: 'read',
    },
  },
  {
    path: '/hero/service/area',
    name: 'hero-service-area',
    component: () => import('../../views/services/hero/serviceArea/ServiceArea.vue'),
    meta: {
      pageTitle: 'Service Area',
      service: 'hero',
      resource: 'Hero-service-area',
      action: 'read',
    },
  },
  {
    path: '/hero/service/area/view/:id',
    name: 'hero-service-area-view',
    component: () => import('../../views/services/hero/serviceArea/ViewServiceArea.vue'),
    meta: {
      pageTitle: 'Service Area View',
      service: 'hero',
      resource: 'Hero-service-area-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Service Area',
          to: '/hero/service/area',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'hero-service-area',
    },
  },
  {
    path: '/hero/recover',
    name: 'hero-recover',
    component: () => import('../../views/services/hero/recover/Recover.vue'),
    meta: {
      pageTitle: 'Recover',
      service: 'hero',
      resource: 'Hero-recover',
      action: 'read',
    },
  },
  {
    path: '/hero/public/relations',
    name: 'hero-public-relations-list',
    component: () => import('../../views/services/hero/publicRelations/List/PublicRelations.vue'),
    meta: {
      pageTitle: 'List',
      service: 'hero',
      resource: 'Hero-public-relations-list',
      action: 'read',
    },
  },
  {
    path: '/hero/public/relations/automation',
    name: 'hero-public-relations-automation',
    component: () => import('../../views/services/hero/publicRelations/Automation/Automation.vue'),
    meta: {
      pageTitle: 'Automation',
      service: 'hero',
      resource: 'Hero-public-relations-automation',
      action: 'read',
    },
  },
  {
    path: '/hero/manage/profile',
    name: 'hero-manage-profile',
    component: () => import('../../views/services/hero/manageProfile/ManageProfile.vue'),
    meta: {
      pageTitle: 'Profile',
      service: 'hero',
      resource: 'Hero-profile',
      action: 'read',
    },
  },
  {
    path: '/hero/services/list',
    name: 'hero-services-list',
    component: () => import('../../views/services/hero/servicesList/ServicesList.vue'),
    meta: {
      pageTitle: 'services list',
      service: 'hero',
      resource: 'Hero-services-list',
      action: 'read',
    },
  },
  {
    path: '/hero/services/list/view/:id',
    name: 'hero-service-list-view',
    component: () => import('../../views/services/hero/servicesList/ViewService.vue'),
    meta: {
      pageTitle: 'Services View',
      service: 'hero',
      resource: 'Hero-services-list',
      action: 'read',
      breadcrumb: [
        {
          text: 'Services list',
          to: '/hero/services/list',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'hero-service-list',
    },
  },
  {
    path: '/hero/services/list/period/:id',
    name: 'hero-service-list-period',
    component: () => import('../../views/services/hero/servicesList/pages/period/Period.vue'),
    meta: {
      pageTitle: 'Queue Services Period',
      service: 'hero',
      resource: 'Hero-services-list',
      action: 'read',
      breadcrumb: [
        {
          text: 'Services list',
          to: '/hero/services/list',
        },
        {
          text: 'Period',
          active: true,
        },
      ],
      navActiveLink: 'hero-service-list',
    },
  },
  {
    path: '/hero/queue/book',
    name: 'hero-queue-book',
    component: () => import('../../views/services/hero/queueSystem/queueBook/QueueBook.vue'),
    meta: {
      pageTitle: 'Queue Book',
      service: 'hero',
      resource: 'Hero-queue-book',
      action: 'read',
    },
  },
  {
    path: '/hero/services/i18n',
    name: 'hero-service-config-i18n',
    component: () => import('../../views/services/hero/i18nSettings/ServiceConfigI18n.vue'),
    meta: {
      pageTitle: 'Service Config i18n',
      service: 'hero',
      resource: 'Hero-services-i18n',
      action: 'read',
    },
  },
  {
    path: '/hero/services/template',
    name: 'hero-services-template',
    component: () => import('../../views/services/hero/outsideWork/servicesTemplate/Services.vue'),
    meta: {
      pageTitle: 'Services Template',
      service: 'hero',
      resource: 'hero-services-template',
      action: 'read',
    },
  },
  {
    path: '/hero/services/template/view/:id',
    name: 'hero-services-template-view',
    component: () => import('../../views/services/hero/outsideWork/servicesTemplate/ViewService.vue'),
    meta: {
      pageTitle: 'Services Template View',
      service: 'hero',
      resource: 'hero-services-template-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Services Template',
          to: '/hero/services/template',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'hero-services-template',
    },
  },
  {
    path: '/hero/services/task',
    name: 'hero-services-task',
    component: () => import('../../views/services/hero/outsideWork/task/Task.vue'),
    meta: {
      pageTitle: 'Task',
      service: 'hero',
      resource: 'hero-services-task',
      action: 'read',
    },
  },
  {
    path: '/hero/services/task/:id',
    name: 'hero-services-task-view',
    component: () => import('../../views/services/hero/outsideWork/task/TaskView.vue'),
    meta: {
      pageTitle: 'Task View',
      service: 'hero',
      resource: 'Hero-services-task-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Task',
          to: '/hero/services/task',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'hero-services-task',
    },
  },
  {
    path: '/hero/line/notify',
    name: 'hero-line-notify',
    component: () => import('../../views/services/hero/lineNotify/LineNotify.vue'),
    meta: {
      pageTitle: 'Line Notify',
      service: 'hero',
      resource: 'Hero-line-notify',
      action: 'read',
    },
  },
  {
    path: '/hero/adjust/time/queue',
    name: 'hero-adjust-time-queue',
    component: () => import('../../views/services/hero/adjustTimeQueue/AdjustTimeQueue.vue'),
    meta: {
      pageTitle: 'Adjust Time Queue',
      service: 'hero',
      resource: 'Hero-adjust-time-queue',
      action: 'read',
    },
  },
  {
    path: '/hero/agency',
    name: 'hero-agency',
    component: () => import('../../views/services/hero/agency/Agency.vue'),
    meta: {
      pageTitle: 'Agency',
      service: 'hero',
      resource: 'Hero-agency',
      action: 'read',
    },
  },
  {
    path: '/hero/department',
    name: 'hero-department',
    component: () => import('../../views/services/hero/department/Department.vue'),
    meta: {
      pageTitle: 'Department',
      service: 'hero',
      resource: 'Hero-department',
      action: 'read',
    },
  },
  {
    path: '/hero/question',
    name: 'hero-question',
    component: () => import('../../views/services/hero/question/Question.vue'),
    meta: {
      pageTitle: 'Question',
      service: 'hero',
      resource: 'Hero-question',
      action: 'read',
    },
  },
  {
    path: '/hero/reportpdpa',
    name: 'hero-report-pdpa',
    component: () => import('../../views/services/hero/reportPDPA/ReportPDPA.vue'),
    meta: {
      pageTitle: 'Report PDPA',
      service: 'hero',
      resource: 'Hero-report-pdpa',
      action: 'read',
    },
  },
  {
    path: '/hero/report-service',
    name: 'hero-report-service',
    component: () => import('../../views/services/hero/reportService/ReportService.vue'),
    meta: {
      pageTitle: 'Report Service',
      service: 'hero',
      resource: 'Hero-report-service',
      action: 'read',
    },
  },
  {
    path: '/hero/questionreview',
    name: 'hero-question-review',
    component: () => import('../../views/services/hero/questionReview/QuestionReview.vue'),
    meta: {
      pageTitle: 'Question Review',
      service: 'hero',
      resource: 'Hero-question-review',
      action: 'read',
    },
  },
  {
    path: '/hero/questionreview/:id',
    name: 'hero-question-review-view',
    component: () => import('../../views/services/hero/questionReview/QuestionReviewView.vue'),
    meta: {
      pageTitle: 'Question Review View',
      service: 'hero',
      resource: 'Hero-question-review-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Question Review',
          to: '/hero/questionreview',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'hero-question-review',
    },
  },
  {
    path: '/hero/questionreview-report',
    name: 'hero-question-review-report',
    component: () => import('../../views/services/hero/questionReviewReport/QuestionReviewReport.vue'),
    meta: {
      pageTitle: 'Question Review Report',
      service: 'hero',
      resource: 'Hero-question-review-report',
      action: 'read',
    },
  },
  {
    path: '/hero/questionreview-report/:id',
    name: 'hero-question-review-report-view',
    component: () => import('../../views/services/hero/questionReviewReport/QuestionReviewViewReport.vue'),
    meta: {
      pageTitle: 'Question Review Report View',
      service: 'hero',
      resource: 'Hero-question-review-report-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Question Report Review',
          to: '/hero/questionreview-report',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'hero-question-review-report',
    },
  },
  // {
  //   path: '/hero/services/period/:id',
  //   name: 'hero-services-period',
  //   component: () => import('@/views/hero/services/pages/period/Period.vue'),
  //   meta: {
  //     pageTitle: 'Queue Services Period',
  //     service: 'hero',
  //     resource: 'hero-services-period',
  //     action: 'read',
  //     breadcrumb: [
  //       {
  //         text: 'Queue Services',
  //         to: '/hero/services',
  //       },
  //       {
  //         text: 'Period',
  //         active: true,
  //       },
  //     ],
  //     navActiveLink: 'hero-services',
  //   },
  // },
]
