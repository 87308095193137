export default [
  {
    route: 'delivery-dashboard',
    title: 'delivery-dashboard',
    slug: 'dashboard',
    icon: 'HomeIcon',
    i18n: 'Dashboard',
    resource: 'Delivery-dashboard',
    action: 'read',
  },
  {
    route: null,
    title: 'delivery-users',
    slug: 'users',
    icon: 'UsersIcon',
    resource: 'Delivery-users',
    i18n: 'Users',
    children: [
      {
        route: 'delivery-users-admin',
        title: 'delivery-users-admin',
        slug: 'userAdmin',
        icon: 'BriefcaseIcon',
        i18n: 'Admin',
        resource: 'Delivery-users-admin',
        action: 'read',
      },
      {
        route: 'delivery-users-staff',
        title: 'delivery-users-staff',
        slug: 'userStaff',
        icon: 'PackageIcon',
        i18n: 'Staff',
        resource: 'Delivery-users-staff',
        action: 'read',
      },
    ],
  },
]
