export default [
  {
    path: '/select/franchise',
    name: 'select-franchise',
    component: () => import('../views/franchise/selectFranchise/SelectFranchise.vue'),
    meta: {
      pageTitle: 'Select Franchise',
      layout: 'full',
      action: 'read',
      service: 'Auth',
      resource: 'Auth',
    },
  },
  {
    path: '/franchise',
    meta: {
      pageTitle: 'Dashboard',
      service: 'franchise',
      resource: 'Franchise-dashboard',
      action: 'read',
    },
    redirect: { name: 'franchise-dashboard' },
  },
  {
    path: '/franchise/dashboard',
    name: 'franchise-dashboard',
    component: () => import('../views/franchise/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      service: 'franchise',
      resource: 'Franchise-dashboard',
      action: 'read',
    },
  },
  {
    path: '/franchise/users/ability/:id',
    name: 'franchise-users-ability',
    component: () => import('../views/franchise/users/ability/Settings.vue'),
    meta: {
      pageTitle: 'Users Ability',
      service: 'franchise',
      resource: 'Franchise-users-ability',
      action: 'read',
      breadcrumb: [
        {
          text: 'Staff',
          to: '/franchise/users/staff',
        },
        {
          text: 'Ability',
          active: true,
        },
      ],
      navActiveLink: 'franchise-users-staff',
    },
  },
  {
    path: '/franchise/users/admin',
    name: 'franchise-users-admin',
    component: () => import('../views/franchise/users/Users.vue'),
    meta: {
      pageTitle: 'Users Admin',
      service: 'franchise',
      resource: 'Franchise-users-admin',
      action: 'read',

    },
  },
  {
    path: '/franchise/users/staff',
    name: 'franchise-users-staff',
    component: () => import('../views/franchise/users/Users.vue'),
    meta: {
      pageTitle: 'Users Staff',
      service: 'franchise',
      resource: 'Franchise-users-staff',
      action: 'read',

    },
  },
  {
    path: '/franchise/select/package/services',
    name: 'franchise-select-package-services',
    component: () => import('../views/franchise/selectPackageServices/SelectPackageServices.vue'),
    meta: {
      pageTitle: 'Select Package Services',
      service: 'franchise',
      resource: 'Franchise-select-package-services',
      action: 'read',
    },
  },
  {
    path: '/franchise/create/package/application',
    name: 'franchise-create-package-application',
    component: () => import('../views/franchise/createPackageApplication/CreatePackageApplication.vue'),
    meta: {
      pageTitle: 'Create Package Application',
      service: 'franchise',
      resource: 'Franchise-create-package-application',
      action: 'read',
    },
  },
  {
    path: '/franchise/company',
    name: 'franchise-company',
    component: () => import('../views/franchise/company/Company.vue'),
    meta: {
      pageTitle: 'Company',
      service: 'franchise',
      resource: 'Franchise-company',
      action: 'read',
    },
  },
  {
    path: '/franchise/application',
    name: 'franchise-application',
    component: () => import('../views/franchise/application/Application.vue'),
    meta: {
      pageTitle: 'Application',
      service: 'franchise',
      resource: 'Franchise-application',
      action: 'read',
    },
  },
]
